<template>
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0"
                    :span="24"
                    class="organizetoolbar"
                    style="padding-bottom: 0px">
                <el-form :inline="true"
                         ref="selectForm"
                         :model="selectForm"
                         class="select_form">
                    <el-form-item label="名称:">
                        <el-input v-model="selectForm.name"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList"
                             @callFunction="callFunction"
                             :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="handleShow"
                      class="new_table">
                <el-table-column prop="Name" label="名称"> </el-table-column>
                <el-table-column label="缴费基数" prop="BaseSalary"></el-table-column>
                <el-table-column label="养老保险(公司)" prop="Factory1"></el-table-column>
                <el-table-column label="医疗保险(公司)" prop="Factory2"></el-table-column>
                <el-table-column label="失业保险(公司)" prop="Factory3"></el-table-column>
                <el-table-column label="工伤保险(公司)" prop="Factory4"></el-table-column>
                <el-table-column label="生育保险(公司)" prop="Factory5"></el-table-column>
                <el-table-column label="养老保险(个人)" prop="User1"></el-table-column>
                <el-table-column label="医疗保险(个人)" prop="User2"></el-table-column>
                <el-table-column label="失业保险(个人)" prop="User3"></el-table-column>
                <el-table-column label="工伤保险(个人)" prop="User4"></el-table-column>
                <el-table-column label="生育保险(个人)" prop="User5"></el-table-column>
                <el-table-column label="总缴费(个人)" prop="UserActualTotal"></el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog :title="operation ? '新增' : '编辑'"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm"
                     :model="SakaryForm"
                     class="demo-form-inline"
                     label-width="150px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="名称:" prop="Name" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Name"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="缴费基数:" prop="BaseSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.BaseSalary" @blur="inputMoney('BaseSalary')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="养老保险(公司):" prop="Factory1" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Factory1" @blur="inputMoney('Factory1')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="养老保险(个人):" prop="User1" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.User1" @blur="inputMoney('User1')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="医疗保险(公司):" prop="Factory2" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Factory2" @blur="inputMoney('Factory2')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="医疗保险(个人):" prop="User2" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.User2" @blur="inputMoney('User2')"></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="失业保险(公司):" prop="Factory3" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Factory3" @blur="inputMoney('Factory3')"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="失业保险(个人):" prop="User3" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.User3" @blur="inputMoney('User3')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工伤保险(公司):" prop="Factory4" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Factory4" @blur="inputMoney('Factory4')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工伤保险(个人):" prop="User4" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.User4" @blur="inputMoney('User4')"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="生育保险(公司):" prop="Factory5" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.Factory5" @blur="inputMoney('Factory5')"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生育保险(个人):" prop="User5" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.User5" @blur="inputMoney('User5')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总缴费(个人):" prop="UserActualTotal" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.UserActualTotal" @blur="inputMoney('UserActualTotal')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="operation1">
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetDicInsurancePageList,
  AddDicInsurance,
  UpdateDicInsurance,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      loading: true,
      searchVal: "",
      selectForm: {
        name: "",
      },
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      buttonList: [],
      SakaryForm: {
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
          User5: "",
          UserActualTotal: "",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
      };
      console.log(para);
      GetDicInsurancePageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm = Object.assign({}, row);
      (this.operation = false), (this.SakaryVisible = true);
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
          User5: "",
          UserActualTotal: "",
      }),
        (this.operation = true),
        (this.SakaryVisible = true);
      this.operation1 = true;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          if (this.operation) {
            //新增
            AddDicInsurance(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
            UpdateDicInsurance(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

